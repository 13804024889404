import Iframe from './components/Iframe'
import Background from './components/Background'
import ResetChatButton from './components/ResetChatButton'
import Body from './components/Body'
import getScriptData from './helpers/getScriptData'
import toggleZoomImage from './helpers/toggleZoomImage'
import updateStorage from './helpers/updateStorage'
import resetStorage from './helpers/resetStorage'
import removeTabId from './helpers/removeTabId'
import toggleTabId from './helpers/toggleTabId'
import debounce from './helpers/debounce'

class Main {
  constructor() {
    this.staticData = getScriptData()
    this.iframe = new Iframe(this.staticData)
    this.background = new Background()
    this.resetChatButton = new ResetChatButton()
    this.body = new Body(this.iframe)
    this.tabId = new Date().getTime()
  }

  async init() {
    await this.handleTimeout()
    this.iframe.init()
    this.background.init()

    if (this.staticData.isTestMode) {
      this.resetChatButton.setOnClick(() => this.iframe.postMessageResetWidgetChatHistory())
    }

    this.#addMessageListener()
    this.#addBeforeUploadListener()
    this.#addStorageListener()
    this.#addResizeListener()
  }

  async handleTimeout() {
    if (this.staticData.awaitBeforeInitSeconds > 0) {
      const timeoutSeconds = Number(this.staticData.awaitBeforeInitSeconds) * 1000
      await new Promise(resolve => setTimeout(resolve, timeoutSeconds))
    }
  }

  #addMessageListener() {
    window.addEventListener('message', event => {
      if (event.data.type === 'widget-background') {
        this.background.onToggleBackground(event.data.background)
      }
      if (event.data.type === 'zoom-image') {
        toggleZoomImage(event.data.imageUrl)
      }
      if (event.data.type === 'widget-set-url') {
        this.iframe.postMessageInitialization(event.data.chatId)
      }
      if (event.data.type === 'update-widget-position') {
        this.iframe.setWidgetMargin(event.data.widgetPosition, event.data.isWidgetOpen)
      }
      if (event.data.type === 'resize-widget') {
        this.#handleResizeWidget(event)
      }
      if (event.data.type === 'reload-page') {
        resetStorage(this.staticData.botId)
        window.location.reload()
      }
      if (event.data.type === 'clear-storage') {
        resetStorage(this.staticData.botId)
      }
      if (event.data.type === 'toggle-widget') {
        this.#handleToggleWidget(event)
      }
      if (event.data.type === 'update-local-storage') {
        updateStorage(this.staticData.botId, event.data.storage)
      }
      if (event.data.type === 'set-browser-tab-name') {
        document.title = `${event.data.botName} - Test Widget`
      }

      this.iframe.onResizeIframe({ width: event.data.width, height: event.data.height })
    })
  }

  #addBeforeUploadListener() {
    window.addEventListener('beforeunload', () => {
      if (this.staticData.isTestMode) {
        this.resetChatButton.unmount(() => this.iframe.postMessageResetWidgetChatHistory())
      }
      removeTabId(this.staticData.botId, this.tabId)
    })
  }

  #addStorageListener() {
    window.addEventListener('storage', event => {
      if (event.key === `tabs-${this.staticData.botId}`) {
        this.iframe.postMessageWidgetHidden(Object.values(JSON.parse(event.newValue)).some(value => value))
      }
    })
  }

  #addResizeListener() {
    window.addEventListener(
      'resize',
      debounce(() => {
        this.iframe.postMessageWidgetResize()
      }),
    )
  }

  #handleResizeWidget(event) {
    this.iframe.onToggleFullScreen(event.data.isFullScreenWidget, event.data.isMobile)
  }

  #handleToggleWidget(event) {
    if (this.staticData.isMobile) {
      this.body.onToggleOverflow(event.data.isWidgetOpen)
    }
    const tabs = toggleTabId(event.data.isWidgetOpen, this.staticData.botId, this.tabId)
    this.iframe.postMessageWidgetHidden(Object.values(tabs).some(value => value))
  }
}

export default Main
