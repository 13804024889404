function toggleTabId(isWidgetOpen, botId, tabId) {
  const tabs = JSON.parse(localStorage.getItem(`tabs-${botId}`)) || {}

  tabs[tabId] = isWidgetOpen

  localStorage.setItem(`tabs-${botId}`, JSON.stringify(tabs))
  return tabs
}

export default toggleTabId
