import getAttributes from '../helpers/getAttributes'

class Iframe {
  constructor(scriptData) {
    this.element = document.createElement('iframe')
    this.scriptData = scriptData
    this.body = document.getElementsByTagName('body')[0]
    this.head = document.getElementsByTagName('head')[0]
    // This will create a default 10px padding (12px(bubbleButtonPadding) - 2px = 10px) for the mobile
    this.defaultMobileHorizontalMargin = '-2px'
  }

  init() {
    this.element.setAttribute('allow', `geolocation ${this.scriptData.src}`)
    this.element.setAttribute('src', `${this.scriptData.src}?ref=${this.scriptData.ref}&open=${!!this.scriptData.open}`)
    this.element.setAttribute('id', 'botscrew-widget')
    this.#setStyle()

    this.#generateHeadFont()
    this.body.appendChild(this.element)
  }

  unmount() {
    this.element.remove()
  }

  #setStyle() {
    const styles = {
      position: 'fixed',
      zIndex: '10001',
      border: 'none',
      height: '0px',
      width: '0px',
      colorScheme: 'only light',
      bottom: this.#getBottomStyle(),
      right: this.#getRightStyle(false),
    }
    Object.assign(this.element.style, styles)
  }

  #getBottomStyle() {
    this.widgetPosition?.bottom || '0px'
  }

  #getRightStyle(isFullScreenWidget) {
    if (isFullScreenWidget) {
      return 'calc((100% - 68vw) / 2)'
    } else {
      return this.widgetPosition?.right || this.widgetPosition?.left || '0px'
    }
  }

  setWidgetMargin(widgetPosition, isWidgetOpen) {
    const { corner, verticalMargin, horizontalMargin } = widgetPosition
    const [startMarginFromYAxis, startMarginFromXAxis] = corner.split('_')

    const newStyles = {
      [startMarginFromYAxis.toLowerCase()]: `${verticalMargin}px`,
      [startMarginFromXAxis.toLowerCase()]: `${horizontalMargin}px`,
    }

    this.widgetPosition = newStyles

    // Handle case when widget is open by default on mobile
    if (isWidgetOpen && this.scriptData.isMobile) {
      return Object.assign(this.element.style, { bottom: '0', right: '0', left: '0' })
    }
    Object.assign(this.element.style, newStyles)
  }

  #generateHeadFont() {
    const meta = document.createElement('link')
    meta.href = 'https://fonts.googleapis.com/css?family=Nunito:300,400,700'
    meta.rel = 'stylesheet'
    this.head.appendChild(meta)
  }

  onToggleFullScreen(isFullScreenWidget, isMobile) {
    const styles = {
      bottom: this.#getBottomStyle(),
      ...(this.widgetPosition.right
        ? { right: isMobile ? this.defaultMobileHorizontalMargin : this.#getRightStyle(isFullScreenWidget) }
        : { left: isMobile ? this.defaultMobileHorizontalMargin : this.#getRightStyle(isFullScreenWidget) }),
    }
    Object.assign(this.element.style, styles)
  }

  onResizeIframe(size) {
    const styles = { ...size }
    Object.assign(this.element.style, styles)
  }

  postMessageInitialization(chatId) {
    const tabs = JSON.parse(localStorage.getItem(`tabs-${this.scriptData.botId}`)) || {}
    const existingChatId = JSON.parse(localStorage.getItem(`widget-${this.scriptData.botId}`))?.chatId

    this.element.contentWindow.postMessage(
      {
        type: 'initialization',
        botId: this.scriptData.botId,
        url: window.location.href,
        serverUrl: this.scriptData.serverUrl,
        originUrl: window.location.href,
        userAgent: navigator.userAgent,
        isMobile: this.scriptData.isMobile,
        isMinimizedWidget: this.scriptData.isMinimizedWidget,
        open: this.scriptData.open,
        attributes: getAttributes(),
        device: {
          width: this.scriptData.isMobile ? document.documentElement.clientWidth : 0,
          height: this.scriptData.isMobile ? document.documentElement.clientHeight : 0,
        },
        storage: JSON.parse(localStorage.getItem(`widget-${this.scriptData.botId}`)) || {},
        isWidgetOpen: Object.values(tabs).some(value => value),
        screenSize: {
          width: document.documentElement.clientWidth,
          height: document.documentElement.clientHeight,
        },
        isTestMode: this.scriptData.isTestMode,
        chatId: existingChatId || chatId,
        visitorId: existingChatId || chatId,
        browserLanguage: navigator.language,
      },
      this.scriptData.src,
    )
  }

  postMessageResetWidgetChatHistory() {
    this.element.contentWindow.postMessage(
      {
        type: 'reset-chat-history',
      },
      this.scriptData.src,
    )
  }

  postMessageToggleWidget() {
    this.element.contentWindow.postMessage(
      {
        type: 'open-widget',
      },
      this.scriptData.src,
    )
  }

  postMessageSubmitGreeting(message) {
    this.element.contentWindow.postMessage(
      {
        type: 'submit-greeting',
        message,
      },
      this.scriptData.src,
    )
  }

  postMessageCloseGreeting(message) {
    this.element.contentWindow.postMessage(
      {
        type: 'close-greeting',
        message,
      },
      this.scriptData.src,
    )
  }

  postMessageWidgetHidden(isWidgetOpen) {
    this.element.contentWindow.postMessage(
      {
        type: 'is-widget-open-at-some-tab',
        isWidgetOpen,
      },
      this.scriptData.src,
    )
  }

  postMessageWidgetResize() {
    if (!this.scriptData.isMobile) {
      this.element.contentWindow.postMessage(
        {
          type: 'widget-resize-screen',
          screenSize: {
            width: window.innerWidth,
            height: window.innerHeight,
          },
        },
        this.scriptData.src,
      )
    }
  }
}

export default Iframe
