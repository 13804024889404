class Background {
  constructor() {
    this.element = document.createElement('div')
    this.body = document.getElementsByTagName('body')[0]
  }

  init() {
    this.#setStyle()
    this.body.appendChild(this.element)
  }

  unmount() {
    this.element.remove()
  }

  #setStyle() {
    const styles = {
      backgroundColor: 'RGBA(0,0,0,.5)',
      width: '100vw',
      height: '100vh',
      position: 'fixed',
      zIndex: '9999',
      top: '0',
      left: '0',
      display: 'none',
    }
    Object.assign(this.element.style, styles)
  }

  onToggleBackground(show) {
    if (show) {
      this.element.style.display = 'block'
    } else {
      this.element.style.display = 'none'
    }
  }
}

export default Background
