import getDeviceCategory from './getDeviceCategory'
import getBrowser from './getBrowser'

function getAttributes() {
  return [
    { name: 'pageUrl', value: window.location.href },
    { name: 'browserLanguage', value: navigator.language },
    { name: 'deviceCategory', value: getDeviceCategory() },
    { name: 'browser', value: getBrowser() },
    {
      name: 'widgetMetadata',
      value: document.getElementById('chatbot-initials-script').getAttribute('data-widget-metadata'),
    },
  ]
}

export default getAttributes
