import getQueryVariable from './getQueryVariable'

function getScriptData() {
  const scriptTag = document.getElementById('chatbot-initials-script')
  const awaitBeforeInitSecondsStr = scriptTag.getAttribute('await-before-init-seconds')

  return Object.freeze({
    botId: scriptTag.getAttribute('bot-id') || scriptTag.getAttribute('data-bot-id'),
    src: scriptTag.getAttribute('src').replace('script-chatbot.js', ''),
    serverUrl: scriptTag.getAttribute('server-url') || scriptTag.getAttribute('data-server-url'),
    iconUrl: scriptTag.getAttribute('data-icon-url'),
    iconScale: scriptTag.getAttribute('data-icon-scale') || 1,
    iconPosition: scriptTag.getAttribute('data-icon-position')?.split(' ') || [],
    open: getQueryVariable('open') === 'true' || scriptTag.getAttribute('data-open-widget') === 'true',
    ref: getQueryVariable('ref'),
    isMobile: /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
    isTestMode: getQueryVariable('isTestMode') === 'true',
    isMinimizedWidget: getQueryVariable('minimizeWidget') === 'true',
    awaitBeforeInitSeconds: awaitBeforeInitSecondsStr === '0' ? 0 : parseFloat(awaitBeforeInitSecondsStr) || 1.5,
  })
}

export default getScriptData
