function toggleZoomImage(url) {
  if (url === '') return

  const styleId = 'chatbot-img-style-id'
  const containerId = 'chatbot-img-container'
  const imageId = 'chatbot-img'
  const closeBtnId = 'chatbot-img-close-btn'

  const container = document.createElement('div')
  const image = container.appendChild(document.createElement('img'))
  const closeBtn = container.appendChild(document.createElement('div'))

  const containerStyle = `
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 10011;
        background: RGBA(0, 0, 0, .9);
        overflow: auto;
    `

  const imageStyle = `
        display: block;
        width: calc(100vw - 20%);
        height: calc(100vh - 20%);
        object-fit: contain;
    `
  const imageStyleActive = `
        width: 100%;
        height: auto;
    `

  const closeBtnStyle = `
        position: fixed;
        right: 20px;
        top: 20px;
        width: 40px;
        height: 40px;
        background: RGBA(0, 0, 0, .1);
        border-radius: 4px;
        z-index: 3;
        cursor: pointer;
    `
  const closeBtnLinesStyle = `
        position: absolute;
        left: 19px;
        top: 8px;
        content: ' ';
        height: 24px;
        width: 2px;
        background: #ffffff;
    `

  const style = document.createElement('style')
  style.setAttribute('id', styleId)

  style.innerHTML = `
        #${containerId} {
            ${containerStyle}
        }
        #${imageId} {
            ${imageStyle}
        }
        #${imageId}.active {
            ${imageStyleActive}
        }
        #${closeBtnId} {
            ${closeBtnStyle}
        }
        #${closeBtnId}:before,
        #${closeBtnId}:after {
            ${closeBtnLinesStyle}
        }
        #${closeBtnId}:before {
            transform: rotate(45deg);
        }
        #${closeBtnId}:after {
            transform: rotate(-45deg);
        }
    `

  document.head.appendChild(style)

  container.setAttribute('id', containerId)

  image.setAttribute('id', imageId)
  image.setAttribute('src', url)

  closeBtn.setAttribute('id', closeBtnId)

  document.body.appendChild(container)

  const containerRef = document.getElementById(containerId)
  const styleRef = document.getElementById(styleId)
  containerRef.addEventListener('click', closeImg)

  const imageRef = document.getElementById(imageId)
  imageRef.addEventListener('click', function (e) {
    e.stopPropagation()
  })

  const closeBtnRef = document.getElementById(closeBtnId)
  closeBtnRef.addEventListener('click', closeImg)

  function closeImg() {
    containerRef.remove()
    styleRef.remove()
  }
}

export default toggleZoomImage
