class Body {
  constructor(widgetIframe) {
    this.element = document.getElementsByTagName('body')[0]
    this.overflow = window.getComputedStyle(document.getElementsByTagName('body')[0]).overflow
    this.position = window.getComputedStyle(document.getElementsByTagName('body')[0]).position
    this.top = window.getComputedStyle(document.getElementsByTagName('body')[0]).top
    this.widgetIframe = widgetIframe
    this.scrollPosition = 0
  }

  onToggleOverflow(preventScroll) {
    if (preventScroll) {
      this.scrollPosition = window.pageYOffset
      this.element.style.overflow = 'hidden'
      this.element.style.position = 'fixed'
      this.element.style.top = `${this.scrollPosition}px`
      // Handle iframe positioning if case of opening on mobile device
      Object.assign(this.widgetIframe.element.style, { bottom: '0', right: '0', left: '0' })
    } else {
      this.element.style.overflow = this.overflow
      this.element.style.position = this.position
      this.element.style.top = this.top
      window.scrollTo({ top: this.scrollPosition, left: 0, behavior: 'auto' })

      // Handle iframe positioning to the config position
      const toggledPosition =
        'right' in this.widgetIframe.widgetPosition
          ? {
            ...this.widgetIframe.widgetPosition,
            left: 'inherit',
            right: this.widgetIframe.defaultMobileHorizontalMargin,
          }
          : {
            ...this.widgetIframe.widgetPosition,
            right: 'inherit',
            left: this.widgetIframe.defaultMobileHorizontalMargin,
          }
      Object.assign(this.widgetIframe.element.style, toggledPosition)
    }
  }
}

export default Body
