import getIsMobile from './getIsMobile'
import getIsMobileTablet from './getIsMobileTablet'

function getDeviceCategory() {
  if (getIsMobile()) return 'Mobile'
  if (getIsMobileTablet()) return 'Tablet'
  return 'Desktop'
}

export default getDeviceCategory
