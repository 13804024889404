class ResetChatButton {
  constructor() {
    this.element = document.getElementById('reset-chat-button')
  }

  unmount(onClick) {
    this.element?.removeEventListener('click', onClick)
  }

  setOnClick(onClick) {
    this.element?.addEventListener('click', onClick)
  }
}

export default ResetChatButton
